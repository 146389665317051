/*!
 * Minimal Mistakes Jekyll Theme 4.19.1 by Michael Rose
 * Copyright 2013-2019 Michael Rose - mademistakes.com | @mmistakes
 * Licensed under MIT (https://github.com/mmistakes/minimal-mistakes/blob/master/LICENSE)
*/

/* Variables */
@import "minimal-mistakes/variables";

/* Mixins and functions */
@import "minimal-mistakes/vendor/breakpoint/breakpoint";
@include breakpoint-set("to ems", true);
@import "minimal-mistakes/vendor/magnific-popup/magnific-popup"; // Magnific Popup
@import "minimal-mistakes/vendor/susy/susy";
@import "minimal-mistakes/mixins";

/* Core CSS */
@import "minimal-mistakes/reset";
@import "minimal-mistakes/base";
@import "minimal-mistakes/forms";
@import "minimal-mistakes/tables";
@import "minimal-mistakes/animations";

/* Components */
@import "minimal-mistakes/buttons";
@import "minimal-mistakes/notices";
@import "minimal-mistakes/masthead";
@import "minimal-mistakes/navigation";
@import "minimal-mistakes/footer";
@import "minimal-mistakes/search";
@import "minimal-mistakes/syntax";

/* Utility classes */
@import "minimal-mistakes/utilities";

/* Layout specific */
@import "minimal-mistakes/page";
@import "minimal-mistakes/archive";
@import "minimal-mistakes/sidebar";
@import "minimal-mistakes/print";

.site-tag {
    line-height: 0.5;
    margin-top: 0.6em;
    margin-bottom: 0.6em;
    font-size: 1.2rem;
}

.sidebar {
    margin-top: 0.6em;
    margin-bottom: 0.6em;
}

.sidebar.sticky {
    overflow-y: none !important;
   
}

.imgfloat {
    float: left;
    margin-right:.6rem;
    margin-bottom:.7rem;
    margin-top:.5rem;
}

.imgfloatright {
    float: right;
    margin-left:.6rem;
    margin-bottom:.7rem;
}

.site-title {
    font-size:2.6em;
    font-family: "Trebuchet MS", Helvetica, sans-serif;
    font-variant: small-caps;
}

.page__content{
    font-size:0.85em;
}

.initial-content {
    background-color: rgba(24, 23, 23, 0.733) !important;
}

@media only screen and (max-width: 600px) {
    .site-tag {
        line-height: 0.5;
        margin-top: 0.6em;
        margin-bottom: 0.6em;
        font-size: 2.0rem;
    }
    body {
        font-size:1.3rem;
    }
  }

/*!
.masthead {
    background-color: rgb(77, 13, 13) !important;
}

.masthead__inner-wrap, masterhead__menu {
    background-color: rgb(77, 13, 13) !important;
}

.site-title, .site-nav {
    background-color: rgb(77, 13, 13) !important;
}

html, body {
    background-color: rgba(238, 234, 234, 0.733) !important;
}
*/